// graphql-client.js
import { useMemo } from 'react'
import { GraphQLClient } from 'graphql-hooks'
import memCache from 'graphql-hooks-memcache'
import { createNetworkClient } from './network-client'

let graphQLClient;
const networkClient = createNetworkClient();

function createClient(initialState) {
  return new GraphQLClient({
    ssrMode: typeof window === 'undefined',
    url: process.env.NEXT_PUBLIC_GRAPHQL_API ?? '',
    cache: memCache({ initialState, size: 100 }),
    fetch: networkClient.fetch,
    fetchOptions: { 
      credentials: "include",
    }
  });
}

export function initializeGraphQL(initialState = null) {
  const _graphQLClient = graphQLClient ?? createClient(initialState);

  if (initialState && graphQLClient) {
    graphQLClient.cache = memCache({
      initialState: Object.assign(
        graphQLClient.cache.getInitialState(),
        initialState
      ),
      size: 0
    });
  }

  if (typeof window === 'undefined') return _graphQLClient;
  if (!graphQLClient) graphQLClient = _graphQLClient;

  return _graphQLClient;
}

export function useGraphQLClient(initialState) {
  const store = useMemo(() => initializeGraphQL(initialState), [initialState]);
  return store;
}