import Cookies from "js-cookie";

/**
 * @typedef {Object} NetworkHeaders
 * @property {string} "Content-Type"
 * @property {string} [uid]
 * @property {string} [client]
 * @property {string} ["access-token"]
 * @property {string} ["subscriber-token"]
 */

/**
 * @typedef {Object} NetworkClientOptions
 * @property {NetworkHeaders} [headers]
 */

class ConnectionPool {
  constructor(maxPoolSize = 6) {
    this.connections = new Map();
    this.maxPoolSize = maxPoolSize;
    this.pendingConnections = new Map();
  }

  /**
   * @param {string} key
   * @param {() => Promise<Response>} createConnection
   * @returns {Promise<Response>}
   */
  async acquire(key, createConnection) {
    // If there's already a connection for this key, return it
    if (this.connections.has(key)) {
      return this.connections.get(key);
    }

    // If there's a pending connection for this key, wait for it
    if (this.pendingConnections.has(key)) {
      try {
        return await this.pendingConnections.get(key);
      } catch (error) {
        // If the pending connection fails, remove it and try again
        this.pendingConnections.delete(key);
        return this.acquire(key, createConnection);
      }
    }

    // If we haven't reached max pool size, create new connection
    if (this.connections.size < this.maxPoolSize) {
      const connectionPromise = this.createConnectionWithRetry(key, createConnection);
      this.pendingConnections.set(key, connectionPromise);

      try {
        const connection = await connectionPromise;
        this.connections.set(key, connection);
        this.pendingConnections.delete(key);
        return connection;
      } catch (error) {
        this.pendingConnections.delete(key);
        throw error;
      }
    }

    // Wait for a connection to become available
    try {
      await this.waitForAvailableConnection();
      return this.acquire(key, createConnection);
    } catch (error) {
      console.error('Connection pool error:', error);
      throw new Error('Failed to acquire connection: ' + error.message);
    }
  }

  /**
   * Creates a connection with retry logic
   * @private
   */
  async createConnectionWithRetry(key, createConnection, retries = 3) {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const connection = await createConnection();
        return connection;
      } catch (error) {
        if (attempt === retries) {
          throw error;
        }
        // Wait before retrying (exponential backoff)
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 100));
      }
    }
  }

  /**
   * Waits for a connection to become available
   * @private
   */
  async waitForAvailableConnection(timeout = 5000) {
    const startTime = Date.now();
    
    while (this.connections.size >= this.maxPoolSize) {
      if (Date.now() - startTime > timeout) {
        throw new Error('Connection pool timeout');
      }

      // Wait for any pending connection to complete
      await Promise.race([
        ...Array.from(this.connections.values()),
        new Promise(resolve => setTimeout(resolve, 100))
      ]);

      // Clean up completed connections
      for (const [key, connection] of this.connections) {
        if (connection.status === 'done' || connection.status === 'error') {
          this.connections.delete(key);
        }
      }
    }
  }

  /**
   * Releases a connection from the pool
   * @param {string} key
   */
  release(key) {
    this.connections.delete(key);
    this.pendingConnections.delete(key);
  }
}

/**
 * Creates a network client with connection pooling and enhanced error handling
 * @returns {{fetch: (url: string, options?: NetworkClientOptions) => Promise<Response>}}
 */
export const createNetworkClient = () => {
  const connectionPool = new ConnectionPool();
  const requestTimeouts = new Map();

  /**
   * Gets headers with authentication tokens
   * @returns {NetworkHeaders}
   */
  const getHeaders = () => ({
    "Content-Type": "application/json",
    uid: Cookies.get("uid") ?? undefined,
    client: Cookies.get("client") ?? undefined,
    "access-token": Cookies.get("access-token") ?? undefined,
    "subscriber-token": Cookies.get("subscriber-token") ?? undefined,
  });

  /**
   * Creates a reusable response object with proper error handling
   * @param {Response} response
   * @returns {Promise<Response>}
   */
  const createReusableResponse = async (response) => {
    const clonedResponse = response.clone();
    
    if (!response.ok) {
      const error = new Error(`HTTP error! status: ${response.status}`);
      error.response = response;
      throw error;
    }
    
    try {
      const data = await response.json();
      return {
        ...clonedResponse,
        json: () => Promise.resolve(data),
        text: () => Promise.resolve(JSON.stringify(data)),
        ok: clonedResponse.ok,
        status: clonedResponse.status,
        statusText: clonedResponse.statusText,
        headers: clonedResponse.headers,
        clone: () => ({
          ...clonedResponse,
          json: () => Promise.resolve(data),
          text: () => Promise.resolve(JSON.stringify(data))
        })
      };
    } catch (error) {
      const textData = await clonedResponse.text();
      return {
        ...clonedResponse,
        json: () => Promise.reject(new Error(`Invalid JSON: ${textData}`)),
        text: () => Promise.resolve(textData),
        ok: clonedResponse.ok,
        status: clonedResponse.status,
        statusText: clonedResponse.statusText,
        headers: clonedResponse.headers,
        clone: () => ({
          ...clonedResponse,
          text: () => Promise.resolve(textData)
        })
      };
    }
  };

  /**
   * Creates a timeout promise
   * @param {number} ms - Timeout in milliseconds
   * @returns {Promise<never>}
   */
  const timeoutPromise = (ms) => {
    return new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error(`Request timed out after ${ms}ms`));
      }, ms);
    });
  };

  return {
    /**
     * Fetches a resource with connection pooling, retries, and timeouts
     * @param {string} url 
     * @param {NetworkClientOptions} [options={}] 
     * @returns {Promise<Response>}
     */
    async fetch(url, options = {}) {
      const connectionKey = `${url}-${JSON.stringify(options)}`;

      // Clear any existing timeout for this request
      if (requestTimeouts.has(connectionKey)) {
        clearTimeout(requestTimeouts.get(connectionKey));
        requestTimeouts.delete(connectionKey);
      }

      try {
        const fetchPromise = connectionPool.acquire(connectionKey, async () => {
          const fetchOptions = {
            ...options,
            headers: {
              ...getHeaders(),
              ...options.headers,
              'Connection': 'keep-alive',
            },
            credentials: 'include', // Ensure cookies are sent
          };

          try {
            const response = await Promise.race([
              fetch(url, fetchOptions),
              timeoutPromise(30000) // 30 second timeout
            ]);
            return await createReusableResponse(response);
          } catch (error) {
            if (error.message.includes('timed out')) {
              throw new Error('Request timed out');
            }
            throw error;
          }
        });

        const response = await fetchPromise;
        connectionPool.release(connectionKey);
        return response;
      } catch (error) {
        console.error('Network request failed:', error);
        connectionPool.release(connectionKey);
        
        const enhancedError = new Error(`Network request failed: ${error.message}`);
        enhancedError.originalError = error;
        enhancedError.request = { url, options };
        throw enhancedError;
      }
    }
  };
};