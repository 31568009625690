import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios';
import '../styles/globals.css'
import { useGraphQLClient } from '../lib/graphql-client'
import { ClientContext } from 'graphql-hooks'
import { Provider } from '../store'
import Layout from '../components/Layout';
import GoogleAnalyticsTags from '../components/common/GoogleAnalyticsTags'
import { log } from '@logtail/next';
import ErrorBoundary from './ErrorBoundary';

export default function App({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = async (url, { shallow }) => {
      const url_parts = window.location.hostname.split('.');
      if (url_parts.length > 2) {
        await access_log(url_parts[0])
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  const access_log = async (subdomain, retries = 3) => {
    const axiosInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      headers: {
        "content-type": "application/json",
      },
    });

    try {
      const response = await axiosInstance.post("access_logs", {
        subdomain: subdomain,
      });
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return access_log(subdomain, retries - 1);
      } else {
        throw error;
      }
    }
  };

  const graphQLClient = useGraphQLClient(pageProps.initialGraphQLState)

  return (
    <ErrorBoundary>
      <ClientContext.Provider value={graphQLClient}>
        <Provider>
          <Layout>
            <Component {...pageProps} />
            <GoogleAnalyticsTags />
          </Layout>
        </Provider>
      </ClientContext.Provider>
    </ErrorBoundary>
  );
}

